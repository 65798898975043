import React, { useEffect, useState } from "react";
import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import Web3 from 'web3';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import { Link } from "react-router-dom";
import { Close, Logo, Menu } from "../utils/allImgs";
import "./navbar.css";

import {
  Monthlycorporate,
  Quarterlycorporate,
  StarFill,
  Yearlycorporate,
  loading
} from "../utils/allImgs";
import "./allCards.css";


import Top from "../components/Gotop/top";
import Details from "../components/Minting/CorporateMinting/Details/details";
import Footer from "../layout/Footer/footer";


const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      rpc: "https://eth-mainnet.g.alchemy.com/v2/EI8zrRsRs4HkigqcF04vBysFSm5kD_yC" // required
    }
  },
  coinbasewallet: {
    package: CoinbaseWalletSDK, // Required
    options: {
      appName: "JayTradz", // Required
      rpc: "https://eth-mainnet.g.alchemy.com/v2/EI8zrRsRs4HkigqcF04vBysFSm5kD_yC", // Optional if `infuraId` is provided; otherwise it's required

      darkMode: true // Optional. Use dark theme, defaults to false
    }
  }

};


const contractABI = require("../utils/contract-abi.json");
const usdtABI = require("../utils/usdt-abi.json");
const contractAddress = "0x65d780A86568BC340D04D3eA6D92ED5c3478FD37";//  0x324Ca102D35E323eB60157e520561d57785c39C8
const usdtAddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7";//   0x6BF7c471a8811d7cEE56C703E86809fB3A7b23AF
const stakeABI = require("../utils/stake-abi.json");
const stakeAddress = "0x8056Ab33327Bdd73b2720eC2a9Dbe8EAa4D6C254";



const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0
  
});


const CorporateMinting = (props) => {

  const [provider, setProvider] = useState();
  const [walletAddress, setAddress] = useState("");
  const [sideactive, setSideactive] = useState(false);
  const [class1, setClass1] = useState(true);
  const [class2, setClass2] = useState(false);
  const [class3, setClass3] = useState(false);
  const [class4, setClass4] = useState(false);
  const [class5, setClass5] = useState(false);
  const [class6, setClass6] = useState(false);
  const [class7, setClass7] = useState(false);
  const [monthlyCount, setMCount] = useState(0);
  const [quarterlyCount, setQCount] = useState(0);
  const [semiCount, setSemiCount] = useState(0);
  const [canStake, setCanStake] = useState(false);
  const [id, setId] = useState(1);
  const [message, setWarningMessage] = useState("");
  const [mSelected, setMSelected] = useState(true);
  const [qSelected, setQSelected] = useState(true);
  const [sSelected, setSSelected] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [programEntered, setProgramEntered] = useState(false);



  
  async function connectWallet() {

    try {

      let web3Modal = new Web3Modal({
        network: 'mainnet', // optional
        theme: 'dark',
        cacheProvider: false,

        providerOptions,

      });
      const web3ModalInstance = await web3Modal.connect();
      const provider = new Web3(web3ModalInstance);
      if (web3ModalInstance) {
        setProvider(provider);
        const accounts = await provider.eth.getAccounts();
        const address = accounts[0];
        setAddress(address);
        console.log(address);
      }

    } catch (error) {
      console.error(error)
    }
  }

  const toggleBar = () => {
    setSideactive(true);
  };
  const closeBar = () => {
    setSideactive(false);
  };
  if (sideactive) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }
  let active1 = () => {
    setClass1(true);
    setClass2(false);
    setClass3(false);
    setClass4(false);
    setClass7(false);
    setClass5(false);
    setSideactive(false);
    setClass6(false);
  };
  let active2 = () => {
    setClass1(false);
    setClass2(true);
    setClass3(false);
    setClass4(false);
    setClass5(false);
    setClass7(false);
    setSideactive(false);
    setClass6(false);
  };
  let active3 = () => {
    setClass1(false);
    setClass2(false);
    setClass3(true);
    setClass4(false);
    setClass5(false);
    setClass7(false);
    setSideactive(false);
    setClass6(false);
  };
  let active4 = () => {
    setClass1(false);
    setClass2(false);
    setClass3(false);
    setClass4(true);
    setClass5(false);
    setSideactive(false);
    setClass7(false);
    setClass6(false);
  };
  let active5 = () => {
    setClass1(false);
    setClass2(false);
    setClass3(false);
    setClass4(false);
    setClass5(true);
    setSideactive(false);
    setClass7(false);
    setClass6(false);
  };
  let active6 = () => {
    setClass1(false);
    setClass2(false);
    setClass3(false);
    setClass4(false);
    setSideactive(false);
    setClass5(false);
    setClass6(true);
    setClass7(false);
  };
  let active7 = () => {
    setSideactive(false);
    setClass1(false);
    setClass2(false);
    setClass3(false);
    setClass4(false);
    setClass5(false);
    setClass6(false);
    setClass7(true);
  };

  const onMintPressed = async (id, price) => {


    const nftContract = new provider.eth.Contract(
      contractABI,
      contractAddress
    )
    const usdtContract = new provider.eth.Contract(
      usdtABI,
      usdtAddress
    );

    let count;

    if (id == 16) {
      count = monthlyCount;
    } else if (id == 17) {
      count = quarterlyCount;
    } else {
      count = semiCount;
    }

    setId(id);
    setWarningMessage("Please wait...");


    let total = price * 10 ** 6 * count;

    await usdtContract.methods.approve(contractAddress, provider.utils.toBN(total)).send({ from: walletAddress }).then(() => {
      setWarningMessage("Step 1 of 4 completed. Please wait for confirmation...");
      nftContract.methods.mint(id, count).send({ from: walletAddress, gas: 300000 }).then(()=> {
        setWarningMessage("Steps 2 of 4 completed. Please wait for Start Program button to appear below.")
        setCanStake(true);

      })
    })
  }

  const onStakePressed = async (e) => {
    e.preventDefault();

    const stakeContract = new provider.eth.Contract(
      stakeABI,
      stakeAddress
    );

    const mintContract = new provider.eth.Contract(
      contractABI,
      contractAddress
    )

    let count;

    let refValue;

    if (id == 16) {

      count = monthlyCount;
    } else if (id == 17) {

      count = quarterlyCount;
    } else {

      count = semiCount;
    }

    if (props.value > 0){
      refValue = props.value;
    } else {
      refValue = 0;
    }
    console.log("This is the refValue: "+ props.value)

    setProgramEntered(true);





    await mintContract.methods.setApprovalForAll(stakeAddress, true).send({ from: walletAddress }).then(() => {
      setWarningMessage("Steps 3 of 4 completed. Waiting for confirmation...");
      stakeContract.methods.enterProgram(count, id, refValue).send({ from: walletAddress }).then(() => {
        setSuccessMessage("Go To Member Dashboard");
        closeMessage();
      })

    })

  }

  const incrementMonthlyCount = () => {
    if (monthlyCount < 10) {
      setMCount(monthlyCount + 1);
      setMSelected(true);
      setQSelected(false);
      setSSelected(false);
    }
    
    
  };

  const decrementMonthlyCount = () => {
    if (monthlyCount > 0) {
      setMCount(monthlyCount - 1);
    }
    if (monthlyCount <= 1){
      setMSelected(true);
      setQSelected(true);
      setSSelected(true);
    }
  };
  const incrementQuarterlyCount = () => {
    if (quarterlyCount < 10) {
      setQCount(quarterlyCount + 1);
      setMSelected(false);
      setQSelected(true);
      setSSelected(false);
    } 
    
  };

  const decrementQuarterlyCount = () => {
    if (quarterlyCount > 0) {
      setQCount(quarterlyCount - 1);
    }
    if (quarterlyCount <= 1){
      setMSelected(true);
      setQSelected(true);
      setSSelected(true);
    }
  };
  const incrementSemiAnnualCount = () => {
    if (semiCount < 10) {
      setSemiCount(semiCount + 1);
      setMSelected(false);
      setQSelected(false);
      setSSelected(true);
    }
    
  };

  const decrementSemiAnnualCount = () => {
    if (semiCount > 0) {
      setSemiCount(semiCount - 1);
    }
    if (semiCount <= 1){
      setMSelected(true);
      setQSelected(true);
      setSSelected(true);
    }
  };

  const closeMessage= () => {
    setWarningMessage("");
  }



  return (
    <div>
      <Top />
      <div className="navbar-section">
        <div className="navbar-main">
          <div className="left-bar">
            <Link to="/">
              <img src={Logo} alt="" />
            </Link>
          </div>
          <div
            onClick={closeBar}
            className={sideactive ? " overlay " : "hide-it"}
          ></div>
          <div className="right-bar" id="navMain">
            <ul className={sideactive ? "modalup" : "hide-it"}>
              <li className="mobile-close">
                <img onClick={closeBar} src={Close} alt="" />
              </li>
              <li>
                <a href="/" onClick={active1} className={class1 ? "active" : ""}>
                  Home
                  <span className="underline"></span>
                </a>
              </li>
              <li>
                <a
                  className={class2 ? "active" : ""}
                  href="/#program"
                  onClick={active2}
                >
                  Our Program
                  <span className="underline"></span>
                </a>
              </li>
              <li>
                <a
                  className={class3 ? "active" : ""}
                  href="/#mission"
                  onClick={active3}
                >
                  Mission
                  <span className="underline"></span>
                </a>
              </li>
              <li>
                <a
                  className={class4 ? "active" : ""}
                  href="/#membership"
                  onClick={active4}
                >
                  Memberships
                  <span className="underline"></span>
                </a>
              </li>
              <li>
                <a
                  className={class5 ? "active" : ""}
                  href="/#rewards"
                  onClick={active5}
                >
                  Rewards
                  <span className="underline"></span>
                </a>
              </li>
              <li>
                <a
                  className={class6 ? "active" : ""}
                  href="/#benefits"
                  onClick={active6}
                >
                  Benefits
                  <span className="underline"></span>
                </a>
              </li>
              <li>
                <a
                  className={class7 ? "active" : ""}
                  href="/#faq"
                  onClick={active7}
                >
                  FAQ
                  <span className="underline"></span>
                </a>
              </li>
              <li>
                <a
                  href="./JayTradz-Brochure.pdf"
                  onClick={closeBar}
                >
                  Brochure
                  <span className="underline"></span>
                </a>
              </li>
              <li className="sign-btn">
                <Link to='/memberdashboard'>
                  <button>Sign in</button>
                </Link>
              </li>
              <li>
                {walletAddress.length > 0 ? (
                  <button className='connect-btn-pressed'>
                    {String(walletAddress).substring(0, 6)}
                    {"...."}
                    {String(walletAddress).substring(39)}
                  </button>
                ) : (
                  <button className="connect-btn" onClick={connectWallet}>Connect Wallet</button>
                )}
                
                </li>
            </ul>
            <div className="mobile-bar">
              <img onClick={toggleBar} style={{height: "100%", padding:"10px", marginBottom: "-20px"}} src={Menu} alt="" />
              {walletAddress.length > 0 ? (
                  <button className='connect-btn-pressed' style={{height: "44px"}} >
                    {String(walletAddress).substring(0, 6)}
                    {"...."}
                    {String(walletAddress).substring(39)}
                  </button>
                ) : (
                  <button className="connect-btn" style={{height: "44px"}} onClick={connectWallet}>Connect Wallet</button>
                )}
            </div>
          </div>
        </div>
      </div>

      <div className="minting-cards">
        <div className="card-name">
          <div className="minting-name">Corporate</div>
          <div className="stars">
            <img src={StarFill} alt="" />
            <img src={StarFill} alt="" />
            <img src={StarFill} alt="" />
            <img src={StarFill} alt="" />
            <img src={StarFill} alt="" />
          </div>
        </div>
        <div className="plan-cards">
          {mSelected ? (
            <div className="single-plan">
            <div className="upper-block">
              <img src={Monthlycorporate} alt="" />
              <h2>Corporate Monthly </h2>
            </div>
            <div className="lower-block">
              <div className="minting-calc">
                <div className="calc">
                  <div onClick={decrementMonthlyCount} className="minus-btn">-</div>
                  <div className="quantity">{monthlyCount}</div>
                  <div onClick={incrementMonthlyCount} className="plus-btn">+</div>
                </div>
                <div className="calc-btn">
                  <button onClick={() => { onMintPressed(16, 250000) }}>Mint</button>
                </div>
              </div>
              <div className="last">
              <div className="total-price ">
                  <div className="total-name">Price :</div>
                  <div className="total-ps">$250,000 USDT</div>
                </div>
                <div className="total-price nm">
                  <div className="total-name">total :</div>
                  <div className="total-ps">{formatter.format(250000 * monthlyCount)} USDT</div>
                </div>
                <div className="total-price ">
                  <div className="total-name">** USDT (ERC-20) </div>
                </div>
              </div>
            </div>
          </div>
          ):(
            <div className="single-plan" style={{opacity: "20%"}}>
            <div className="upper-block">
              <img src={Monthlycorporate} alt="" />
              <h2>Corporate Monthly </h2>
            </div>
            <div className="lower-block">
              <div className="minting-calc">
                <div className="calc">
                  <div onClick={decrementMonthlyCount} className="minus-btn">-</div>
                  <div className="quantity">{monthlyCount}</div>
                  <div onClick={incrementMonthlyCount} className="plus-btn">+</div>
                </div>
                <div className="calc-btn">
                  <div>Mint</div>
                </div>
              </div>
              <div className="last">
              <div className="total-price ">
                  <div className="total-name">Price :</div>
                  <div className="total-ps">$250,000 USDT</div>
                </div>
                <div className="total-price nm">
                  <div className="total-name">total :</div>
                  <div className="total-ps">{formatter.format(250000 * monthlyCount)} USDT</div>
                </div>
                <div className="total-price ">
                  <div className="total-name">** USDT (ERC-20) </div>
                </div>
              </div>
            </div>
          </div>
          )}
          
          {qSelected ? (
            <div className="single-plan">
            <div className="upper-block">
              <img src={Quarterlycorporate} alt="" />
              <h2>Corporate Quarterly </h2>
            </div>
            <div className="lower-block">
              <div className="minting-calc">
                <div className="calc">
                  <div onClick={decrementQuarterlyCount} className="minus-btn">-</div>
                  <div className="quantity">{quarterlyCount}</div>
                  <div onClick={incrementQuarterlyCount} className="plus-btn">+</div>
                </div>
                <div className="calc-btn">
                  <button onClick={() => { onMintPressed(17, 250000) }}>Mint</button>
                </div>
              </div>
              <div className="last">
              <div className="total-price ">
                  <div className="total-name">Price :</div>
                  <div className="total-ps">$250,000 USDT</div>
                </div>
                <div className="total-price nm">
                  <div className="total-name">total :</div>
                  <div className="total-ps">{formatter.format(250000 * quarterlyCount)} USDT</div>
                </div>
                <div className="total-price ">
                  <div className="total-name">** USDT (ERC-20) </div>
                </div>
              </div>
            </div>
          </div>
          ):(
            <div className="single-plan" style={{opacity: "20%"}}>
            <div className="upper-block">
              <img src={Quarterlycorporate} alt="" />
              <h2>Corporate Quarterly </h2>
            </div>
            <div className="lower-block">
              <div className="minting-calc">
                <div className="calc">
                  <div onClick={decrementQuarterlyCount} className="minus-btn">-</div>
                  <div className="quantity">{quarterlyCount}</div>
                  <div onClick={incrementQuarterlyCount} className="plus-btn">+</div>
                </div>
                <div className="calc-btn">
                  <div>Mint</div>
                </div>
              </div>
              <div className="last">
              <div className="total-price ">
                  <div className="total-name">Price :</div>
                  <div className="total-ps">$250,000 USDT</div>
                </div>
                <div className="total-price nm">
                  <div className="total-name">total :</div>
                  <div className="total-ps">{formatter.format(250000 * quarterlyCount)} USDT</div>
                </div>
                <div className="total-price ">
                  <div className="total-name">** USDT (ERC-20) </div>
                </div>
              </div>
            </div>
          </div>
          )}
          {sSelected ? (
            <div className="single-plan">
            <div className="upper-block">
              <img src={Yearlycorporate} alt="" />
              <h2>Corporate Semi-Yearly</h2>
            </div>
            <div className="lower-block">
              <div className="minting-calc">
                <div className="calc">
                  <div onClick={decrementSemiAnnualCount} className="minus-btn">-</div>
                  <div className="quantity">{semiCount}</div>
                  <div onClick={incrementSemiAnnualCount} className="plus-btn">+</div>
                </div>
                <div className="calc-btn">
                  <button onClick={() => { onMintPressed(18, 250000) }}>Mint</button>
                </div>
              </div>
              <div className="last">
              <div className="total-price ">
                  <div className="total-name">Price :</div>
                  <div className="total-ps">$250,000 USDT</div>
                </div>
                <div className="total-price nm">
                  <div className="total-name">total :</div>
                  <div className="total-ps">{formatter.format(250000 * semiCount)} USDT</div>
                </div>
                <div className="total-price ">
                  <div className="total-name">** USDT (ERC-20) </div>
                </div>
              </div>
            </div>
          </div>
          ):(
            <div className="single-plan" style={{opacity: "20%"}}>
            <div className="upper-block">
              <img src={Yearlycorporate} alt="" />
              <h2>Corporate Semi-Yearly</h2>
            </div>
            <div className="lower-block">
              <div className="minting-calc">
                <div className="calc">
                  <div onClick={decrementSemiAnnualCount} className="minus-btn">-</div>
                  <div className="quantity">{semiCount}</div>
                  <div onClick={incrementSemiAnnualCount} className="plus-btn">+</div>
                </div>
                <div className="calc-btn">
                  <button onClick={() => { onMintPressed(18, 250000) }}>Mint</button>
                </div>
              </div>
              <div className="last">
              <div className="total-price ">
                  <div className="total-name">Price :</div>
                  <div className="total-ps">$250,000 USDT</div>
                </div>
                <div className="total-price nm">
                  <div className="total-name">total :</div>
                  <div className="total-ps">{formatter.format(250000 * semiCount)} USDT</div>
                </div>
                <div className="total-price ">
                  <div className="total-name">** USDT (ERC-20) </div>
                </div>
              </div>
            </div>
          </div>
          )}
          
        </div>
        <br></br>
        {message.length > 0 && (
          <div id="popup1" class="overlay">
          <div class="popup">
            {canStake ? (
              <h2>Almost there!</h2>
            ):(
              <h2>Processing...</h2>
            )}
            
            <a class="close" onClick={closeMessage}>&times;</a>
            <div class="content" style={{marginTop: "10px"}}>
              {message}
              
              {canStake ?(
                <div className="stake-btn" style={{marginTop: "20px"}}>
                {programEntered? (
                 <img src={loading} style={{display: "flex", margin: "auto", width: "100px", justifyContent:"center"}}alt=""></img>

                ):(
                  <button style={{ display: "flex", margin: "auto", fontWeight: "bolder", height: "50px", justifyContent: "center" }} onClick={onStakePressed}>Start Program</button>

                )}
                </div>
              ):(
                <img src={loading} style={{display: "flex", margin: "auto", width: "100px", justifyContent:"center"}}alt=""></img>
              )}
              
            </div>
          </div>
        </div>
        )}
        <br></br>

        {canStake && (
          <div className="stake-btn">
            {successMessage.length > 0 ?(
              <a href="/memberdashboard"><button style={{ display: "flex", margin: "auto", fontWeight: "bolder", height: "50px", justifyContent: "center" }}>{successMessage}</button></a>
            ):(
              <button style={{ display: "flex", margin: "auto", fontWeight: "bolder", height: "50px", justifyContent: "center" }} onClick={onStakePressed}>Start Program</button>
            )}
            
          </div>
        )}
        {walletAddress.length == 0 && (
          <div id="popup1" class="overlay">
          <div class="popup">
            
            
            <div class="content">
              <h3>Please connect wallet.</h3>
              
            </div>
          </div>
        </div>
        )}
        
      </div>


      <Details />
      <Footer />
    </div>
  );
};

export default CorporateMinting;
