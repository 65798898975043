import React, {useState} from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes,
} from "react-router-dom";
import AdminDashboard from "../pages/AdminDashboard";
import AssociateMinting from "../pages/AssociateMinting";
import ChairmanMinting from "../pages/ChairmanMinting";
import CorporateMinting from "../pages/CorporateMinting";
import ExecutiveMinting from "../pages/ExecutiveMinting";
import Disclaimer from "../pages/Disclaimer";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Terms from "../pages/Terms";




import Home from "../pages/home";
import InternMinting from "../pages/InternMinting";
import Memberdashboard from "../pages/MemberDashboard";
import PresidentMinting from "../pages/PresidentMinting";



const Routerapp = (props) => {
  
  console.log(props.value);
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="admindashboard" element={<AdminDashboard />} />
          <Route path="Memberdashboard" element={<Memberdashboard />} />
          <Route path="internminting" element={<InternMinting value={props.value} />} />
          <Route path="associateminting" element={<AssociateMinting value={props.value} />} />
          <Route path="chairmanminting" element={<ChairmanMinting value={props.value}/>} />
          <Route path="executiveminting" element={<ExecutiveMinting value={props.value}/>} />
          <Route path="corporateminting" element={<CorporateMinting value={props.value}/>} />
          <Route path="presidentminting" element={<PresidentMinting value={props.value}/>} />
          <Route path="disclaimer" element={<Disclaimer />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
          <Route path="terms" element={<Terms />} />

        </Routes>
      </Router>
    </div>
  );
};

export default Routerapp;
