import React, { useEffect, useState } from "react";
import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import Web3 from 'web3';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import { Link } from "react-router-dom";
import Discbursement from "../components/Admindashboard/Discbursement/discbursement";
import Header from "../components/Admindashboard/Header/header";
import Notification from "../components/Admindashboard/Notifications/notification";
import Top from "../components/Gotop/top";
import Activemembership from "../components/Memberdashboard/Activemembership/activemembership";
import Footer from "../layout/Footer/footer";
import Navbar from "../layout/NavBar/navbar";
import "./discbursement.css";
import { bell, redBell } from "../utils/allImgs";
import {
  ColorHorizonatlDots,
  ColorVerticalDots,
  CopyIcon,
  LeftReward,
  RightReward,
} from "../utils/allImgs";
import { Close, Logo, Menu } from "../utils/allImgs";
import { LeftPolly, RightPolly } from "../utils/allImgs";
import "./activemembership.css";

import { Network, Alchemy } from "alchemy-sdk";

// Optional config object, but defaults to demo api-key and eth-mainnet.
const settings = {
  apiKey: "EI8zrRsRs4HkigqcF04vBysFSm5kD_yC", // Replace with your Alchemy API Key.
  network: Network.ETH_GOERLI // Replace with your network.
};
const alchemy = new Alchemy(settings);

// Get logs for a certain address, with specified topics and blockHash



const web3 = createAlchemyWeb3("https://eth-mainnet.g.alchemy.com/v2/EI8zrRsRs4HkigqcF04vBysFSm5kD_yC");


const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      rpc: "https://eth-mainnet.g.alchemy.com/v2/EI8zrRsRs4HkigqcF04vBysFSm5kD_yC" // required
    }
  },
  coinbasewallet: {
    package: CoinbaseWalletSDK, // Required
    options: {
      appName: "JayTradz", // Required
      rpc: "https://eth-mainnet.g.alchemy.com/v2/EI8zrRsRs4HkigqcF04vBysFSm5kD_yC", // Optional if `infuraId` is provided; otherwise it's required

      darkMode: true // Optional. Use dark theme, defaults to false
    }
  }

};

const contractABI = require("../utils/contract-abi.json");
const usdtABI = require("../utils/usdt-abi.json");
const stakeABI = require("../utils/stake-abi.json");
const contractAddress = "0x65d780A86568BC340D04D3eA6D92ED5c3478FD37";//  0x324Ca102D35E323eB60157e520561d57785c39C8
const usdtAddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7";// 0x6BF7c471a8811d7cEE56C703E86809fB3A7b23AF
const stakeAddress = "0x8056Ab33327Bdd73b2720eC2a9Dbe8EAa4D6C254";
const NFT_IPFS_KEY = "QmYADUg4bJm7NLd7VwMAqcYuTp2tiT7B4nnfgsiknD4mNZ/"


const stakeContract = new web3.eth.Contract(
  stakeABI,
  stakeAddress
);

const mintContract = new web3.eth.Contract(
  contractABI,
  contractAddress
)



const AdminDashboard = () => {


  const [provider, setProvider] = useState();
  const [walletAddress, setAddress] = useState("");
  const [sideactive, setSideactive] = useState(false);
  const [class1, setClass1] = useState(true);
  const [class2, setClass2] = useState(false);
  const [class3, setClass3] = useState(false);
  const [class4, setClass4] = useState(false);
  const [class5, setClass5] = useState(false);
  const [class6, setClass6] = useState(false);
  const [class7, setClass7] = useState(false);
  const [monthlyCount, setMCount] = useState(1);
  const [quarterlyCount, setQCount] = useState(1);
  const [semiCount, setSemiCount] = useState(1);
  const [canStake, setCanStake] = useState(false);
  const [id, setId] = useState(1);
  const [listIds, setListIds] = useState([]);
  const [dash, setDashArray] = useState([]);
  const [claimPeriod, setClaimPeriodActive] = useState(false);
  const [claimTime, setClaimTime] = useState(0);
  const [timer, setTimer] = useState("");
  const [total, setTotal] = useState(0);
  const [isAdmin, setAdmin] = useState(false);
  const [prevMonth, setPrevMonth] = useState(0);
  const [currMonth, setCurrMonth] = useState(0);
  const [currQuarter, setCurrQuarter] = useState(0);
  const [currYear, setCurrYear] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [monthEstimate, setEstimateThisMonth] = useState(0);
  const [interns, setInterns] = useState([]);
  const [associates, setAssociates] = useState([]);
  const [executives, setExecutives] = useState([]);
  const [presidents, setPresidents] = useState([]);
  const [chairman, setChairman] = useState([]);
  const [corporate, setCorporate] = useState([]);
  const [notifications, setNotifications] = useState([]);
 
  


  

  useEffect(() => {
    const interval = setInterval(() => {
      setClaimTime(claimTime => claimTime - 1);
    }, 1000);
    return () => clearInterval(interval);
    
  }, [])
  


  const getNotifications = ()=> {

    

    alchemy.core
  .getLogs({
    address: "0x8056Ab33327Bdd73b2720eC2a9Dbe8EAa4D6C254",
    fromBlock: "earliest",
    toBlock: "latest",

  })
  .then((d) => {
    console.log(d);
   
    
    let messages = [];
    for(let i=d.length - 1; i > 0; i--) {
      
      if(d[i].topics[0] == "0x58f286dbee15c187831d708a2a191759f2c64adc4bf0c21743402d027aa6d59b") {
        messages.push({id: i, message: "New Claim Period Open: ", hash: d[i].transactionHash});

        console.log("New Claim Period Open: " + d[i].transactionHash);
        
        
        
      } else if(d[i].topics[0] == "0xdc33abe64775189f2cda4edbfdb6c468d3ec923c885d95e4c0ca195a1bed7480"){
        messages.push({id: i, message: "Monthly Member Start: ", hash: d[i].transactionHash});

        console.log("Monthly Start: " + d[i].transactionHash);
        
      } else if(d[i].topics[0] == "0x27de4c19834e738cce1ee273df3e97bfb0fe7522f63c7fde2095e8c31b398dfd"){
        messages.push({id: i, message: "Quarterly Member Start: ", hash: d[i].transactionHash})

      } else if(d[i].topics[0] == "0x20da64b993a0e1ca1d080aa39914014d228525c23aab0ffbd721e05febe3931e"){
        messages.push({id: i, message: "SemiAnnual Member Start: ", hash: d[i].transactionHash})

      } else if(d[i].topics[0] == "0x698e6ae5d786c1b7ebbb3f65f5c53b321f0c2d2cc5de830ede8183fac06ec425"){
        messages.push({id: i, message: "Cash Payout ", hash: d[i].transactionHash, amount: String(`for $${(parseInt(web3.utils.toBN(d[i].data))/10**6)}:`)})

      } else if(d[i].topics[0] == "0x76a233ff16b2d0854c2063c3080d8e04a464382644f3ca7a65f409d381bcc8bc"){
        messages.push({id: i, message: "Flight Redeemed ", hash: d[i].transactionHash, amount: String(`for $${(parseInt(web3.utils.toBN(d[i].data))/10**6)}:`)})
      } else if(d[i].topics[0] == "0x2ea75d9e084137c84746e39d5496f87901be64f0215ec155bacc7f2c95c7faeb"){
        messages.push({id: i, message: "Hotel Redeemed ", hash: d[i].transactionHash, amount: String(`for $${(parseInt(web3.utils.toBN(d[i].data))/10**6)}:`)})
      } else if(d[i].topics[0] == "0x0fd6457e5f4f5b61d0a5dea18109c6a230ed93f49be5b48fdcb126a74647f2ff"){

        messages.push({id: i, message: "Concert Redeemed ", hash: d[i].transactionHash, amount: String(`for $${(parseInt(web3.utils.toBN(d[i].data))/10**6)}:`)})
      } else if(d[i].topics[0] == "0x6f9d986610e0a010f79e7bcc657d5939a4a8dbe039b5628a34e8bf8799ef43c1") {

        messages.push({id: i, message: "Swag Redeemed ", hash: d[i].transactionHash, amount: String(`for $${(parseInt(web3.utils.toBN(d[i].data))/10**6)}:`)})
      } else if(d[i].topics[0] == "0x473cbab0c154f1666a9caeff31cee09408525cedf7b184d6955bce0ac8dcec73"){

        messages.push({id: i, message: "Admin Points Awarded ", hash: d[i].transactionHash, amount: String(`for $${(parseInt(web3.utils.toBN(d[i].data))/10**6)}:`)})
      } else if(d[i].topics[0] == "0x27785514f6a2819dfa873afc0787b1b0633a2df4163b3a00cb2ffa6753d87634"){
        messages.push({id: i, message: "Claim Period Closed: ", hash: d[i].transactionHash});
      } else if(d[i].topics[0] == "0xbb28353e4598c3b9199101a66e0989549b659a59a54d2c27fbb183f1932c8e6d"){
        
        messages.push({id: i, message: "Refund ", hash: d[i].transactionHash, amount: String(`for $${(parseInt(web3.utils.toBN(d[i].data))/10**6)}:`)})
      } else if(d[i].topics[0] == "0xeeea19ad4eef4e8a1a16a94975c7c498aa1ae7910642deb5c0dee6889614050a"){

        messages.push({id: i, message: "Referral Earned ", hash: d[i].transactionHash});
      }
      
      
    }
    setNotifications([...notifications, ...messages])

  }
  );
}



  useEffect(() => {
    getNotifications();
  }, [])

  const deleteNotification = (id) => {
    setNotifications((current) => 
    current.filter(notifications => notifications.id != id))
  }

  

  

  
  



  async function connectWallet() {

    try {

      let web3Modal = new Web3Modal({
        network: 'mainnet', // optional
        theme: 'dark',
        cacheProvider: false,

        providerOptions,

      });
      const web3ModalInstance = await web3Modal.connect();
      const provider = new Web3(web3ModalInstance);
      if (web3ModalInstance) {
        setProvider(provider);
        const accounts = await provider.eth.getAccounts();
        const address = accounts[0];
        setAddress(address);
        checkPrivilege(address);
        getTimeLeft();
        console.log(address);
      }

    } catch (error) {
      console.error(error)
    }
  }

  const toggleBar = () => {
    setSideactive(true);
  };
  const closeBar = () => {
    setSideactive(false);
  };
  if (sideactive) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }
  let active1 = () => {
    setClass1(true);
    setClass2(false);
    setClass3(false);
    setClass4(false);
    setClass7(false);
    setClass5(false);
    setSideactive(false);
    setClass6(false);
  };
  let active2 = () => {
    setClass1(false);
    setClass2(true);
    setClass3(false);
    setClass4(false);
    setClass5(false);
    setClass7(false);
    setSideactive(false);
    setClass6(false);
  };
  let active3 = () => {
    setClass1(false);
    setClass2(false);
    setClass3(true);
    setClass4(false);
    setClass5(false);
    setClass7(false);
    setSideactive(false);
    setClass6(false);
  };
  let active4 = () => {
    setClass1(false);
    setClass2(false);
    setClass3(false);
    setClass4(true);
    setClass5(false);
    setSideactive(false);
    setClass7(false);
    setClass6(false);
  };
  let active5 = () => {
    setClass1(false);
    setClass2(false);
    setClass3(false);
    setClass4(false);
    setClass5(true);
    setSideactive(false);
    setClass7(false);
    setClass6(false);
  };
  let active6 = () => {
    setClass1(false);
    setClass2(false);
    setClass3(false);
    setClass4(false);
    setSideactive(false);
    setClass5(false);
    setClass6(true);
    setClass7(false);
  };
  let active7 = () => {
    setSideactive(false);
    setClass1(false);
    setClass2(false);
    setClass3(false);
    setClass4(false);
    setClass5(false);
    setClass6(false);
    setClass7(true);
  };

  const checkPrivilege = async (address) => {
    let priv = await stakeContract.methods.canAccessDashboard(address).call();
    setAdmin(priv);
  }

  const isClaimPeriod = async () => {
    let claimPeriodActive = await stakeContract.methods.claimPeriodActive().call();
    setClaimPeriodActive(claimPeriodActive);
  }

  const getTimeLeft = async () => {
    let timeLeft = await stakeContract.methods.getTimeLeft().call();
    setClaimTime(timeLeft);
  }

  useEffect(() => {
    getEstimates();
    getAllMemberships();
  }, [])

  function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d +":" : "";
    var hDisplay = h > 0 ? (h < 10 ? "0"+h+":" : h+":") : "";
    var mDisplay = m > 0 ? (m < 10 ? "0"+m+":" : m+":") : "";
    var sDisplay = s > 0 ? (s < 10 ? "0"+s : s) : "";

    return (`Claim Period ends in ` + dDisplay + hDisplay + mDisplay + sDisplay);
  }

  const getTotals = async () => {
    let totals = await stakeContract.methods.paymentTracker(1).call();

    let [prevMonth, currMonth, currQuarter, currYear, totalPaid] = [totals[0], totals[1], totals[3], totals[5], totals[12]];

    //estimated - prevMonth 

    /*
    uint256 totalLastMonth;
    uint256 totalThisMonth;
    uint256 totalLastQuarter;
    uint256 totalThisQuarter;
    uint256 totalLastYear;
    uint256 totalThisYear;
    uint256 totalRevenueM;
    uint256 totalRevenueQ;
    uint256 totalRevenueS;
    uint256 totalGradBonusM;
    uint256 totalGradBonusQ;
    uint256 totalGradBonusS;
    uint256 totalPaid;
    */
   setPrevMonth(prevMonth/10**6);
   setCurrMonth(currMonth/10**6);
   setCurrQuarter(currQuarter/10**6);
   setCurrYear(currYear/10**6);
   setTotalPaid(totalPaid/10**6);
   
  }

  const getEstimates = async () => {

    let estimates = await stakeContract.methods.getEstimates().call();

    let [monthly, quarterly, semiannually] = [estimates[0], estimates[1], estimates[2]];
    monthly = monthly/10**6;
    quarterly = quarterly/10**6;
    semiannually = semiannually/10**6;

    let estimatedAmount = monthly + (quarterly/3) + (semiannually/6);
    
    

        setEstimateThisMonth(estimatedAmount);
        console.log(monthly);
        console.log(quarterly);
        console.log(semiannually)
        getTotals();
  }

  const getAllMemberships = async () => {
    let intern;
    let associate;
    let executive;
    let president;
    let chairman;
    let corporate;

    let allInterns = await mintContract.methods.getMintedInternTokens().call();
    let allAssocs = await mintContract.methods.getMintedAssociateTokens().call();
    let allExecs = await mintContract.methods.getMintedExecutiveTokens().call()
    let allPresidents = await mintContract.methods.getMintedPresidentialTokens().call();
    let allChairman = await mintContract.methods.getMintedChairmanTokens().call()
    let allCorps = await mintContract.methods.getMintedCorporateTokens().call();

    let [internM, internQ, internS] = [allInterns[0], allInterns[1], allInterns[2]];
    let [assocsM, assocsQ, assocsS] = [allAssocs[0], allAssocs[1], allAssocs[2]];
    let [execsM, execsQ, execsS] = [allExecs[0], allExecs[1], allExecs[2]];
    let [presidentsM, presidentsQ,presidentsS] = [allPresidents[0], allPresidents[1], allPresidents[2]];
    let [chairmanM, chairmanQ, chairmanS] = [allChairman[0], allChairman[1], allChairman[2]];
    let [corpsM, corpsQ, corpsS] = [allCorps[0], allCorps[1], allCorps[2]];
    
    setInterns([internM, internQ, internS]);
    setAssociates([assocsM, assocsQ, assocsS]);
    setExecutives([execsM, execsQ, execsS]);
    setPresidents([presidentsM, presidentsQ,presidentsS]);
    setChairman([chairmanM, chairmanQ, chairmanS]);
    setCorporate([corpsM, corpsQ, corpsS]);

    
  }

  const openClaimPeriod = async () => {
    await stakeContract.methods.openClaimPeriod().send({from: walletAddress});
  }

  const closeClaimPeriod = async () => {
    await stakeContract.methods.openClaimPeriod().send({from: walletAddress});
  }




  return (
    <div>
      <Top />
      <div className="navbar-section">
        <div className="navbar-main">
          <div className="left-bar">
            <Link to="/">
              <img src={Logo} alt="" />
            </Link>
          </div>
          <div
            onClick={closeBar}
            className={sideactive ? " overlay " : "hide-it"}
          ></div>
          <div className="right-bar" id="navMain">
            <ul className={sideactive ? "modalup" : "hide-it"}>
              <li className="mobile-close">
                <img onClick={closeBar} src={Close} alt="" />
              </li>
              <li>
                <a href="/" onClick={active1} className={class1 ? "active" : ""}>
                  Home
                  <span className="underline"></span>
                </a>
              </li>
              <li>
                <a
                  className={class2 ? "active" : ""}
                  href="/#program"
                  onClick={active2}
                >
                  Our Program
                  <span className="underline"></span>
                </a>
              </li>
              <li>
                <a
                  className={class3 ? "active" : ""}
                  href="/#mission"
                  onClick={active3}
                >
                  Mission
                  <span className="underline"></span>
                </a>
              </li>
              <li>
                <a
                  className={class4 ? "active" : ""}
                  href="/#membership"
                  onClick={active4}
                >
                  Memberships
                  <span className="underline"></span>
                </a>
              </li>
              <li>
                <a
                  className={class5 ? "active" : ""}
                  href="/#rewards"
                  onClick={active5}
                >
                  Rewards
                  <span className="underline"></span>
                </a>
              </li>
              <li>
                <a
                  className={class6 ? "active" : ""}
                  href="/#benefits"
                  onClick={active6}
                >
                  Benefits
                  <span className="underline"></span>
                </a>
              </li>
              <li>
                <a
                  className={class7 ? "active" : ""}
                  href="/#faq"
                  onClick={active7}
                >
                  FAQ
                  <span className="underline"></span>
                </a>
              </li>
              <li>
                <a
                  href="./JayTradz-Brochure.pdf"
                  onClick={closeBar}
                >
                  Brochure
                  <span className="underline"></span>
                </a>
              </li>

              <li>
                {walletAddress.length > 0 ? (
                  <button className='connect-btn-pressed'>
                    {String(walletAddress).substring(0, 6)}
                    {"...."}
                    {String(walletAddress).substring(39)}
                  </button>
                ) : (
                  <button className="connect-btn" onClick={connectWallet}>Connect Wallet</button>
                )}




              </li>
            </ul>
            <div className="mobile-bar">
              <img onClick={toggleBar} src={Menu} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Header />
      {walletAddress.length > 0 ? (
        <>
          {isAdmin ? (
            <>
              <div className="rewards-section pt-section dics" id="rewards">
                <div className="left-reward">
                  <img src={LeftReward} alt="" />
                </div>
                <div className="right-reward">
                  <img src={RightReward} alt="" />
                </div>
                <br></br>
                {claimTime > 0 &&(
                   <p style={{display: "flex", margin: "auto", justifyContent: "center", textAlign: "center", fontWeight: "bolder", fontSize: "1.5rem"}}>{secondsToDhms(claimTime)}</p>
                )}
               
                <br></br>
                
                {claimTime > 0 ?(
                  <>
                  <button  className="ref-btn" style={{marginTop: "10px", fontWeight: "bolder", backgroundColor: "#D1D1DB"}}>Open Claim Period</button>
                  <button style={{marginTop: "10px", fontWeight: "bolder", backgroundColor: "#D94441"}} onClick={closeClaimPeriod}className="ref-btn">Close Claim Period</button>
                      
                  </>
                ):(
                  <>
                  <button style={{marginTop: "10px", fontWeight: "bolder"}} onClick={openClaimPeriod}className="ref-btn">Open Claim Period</button>
                <button style={{marginTop: "10px", fontWeight: "bolder", backgroundColor: "#D1D1DB"}} className="ref-btn">Close Claim Period</button>
                </>
                )}
                
                     
                      <br></br>
                <div className="disc-box">
                  <h2 className="point-number">{parseInt(monthEstimate).toFixed(2)}</h2>
                  <p>{parseInt(monthEstimate - prevMonth).toFixed(0)} (from previous period)</p>
                </div>

                <p className="disc-para">
                  DISBURSEMENTS FOR THIS <br /> PERIOD
                </p>

                <div className="point-boxes disc-bx">
                  <div className="indiv-box indiv-disc">
                    <div className="border-box disc-border">
                      <h2>{parseInt(currMonth).toFixed(2)}</h2>
                    </div>
                    <h3>
                      claimed <br /> (Current period)
                    </h3>
                  </div>
                  <div className="indiv-box indiv-disc">
                    <div className="border-box disc-border">
                      <h2>{parseInt(monthEstimate - currMonth).toFixed(2)}</h2>
                    </div>
                    <h3>
                      unclaimed <br /> (Current period)
                    </h3>
                  </div>
                </div>
                <div className="italic-point">
                  <p>total Reward Points Distributed:</p>
                </div>
                <div className="point-boxes disc-bx three-bx">
                  <div className="indiv-box indiv-disc">
                    <div className="border-box disc-border">
                      <h2>{parseInt(currQuarter).toFixed(2)}</h2>
                    </div>
                    <h3>
                      Quarter <br /> (Past 90 days)
                    </h3>
                  </div>
                  <div className="indiv-box indiv-disc">
                    <div className="border-box disc-border">
                      <h2>{parseInt(currYear).toFixed(2)}</h2>
                    </div>
                    <h3>
                      year <br /> (This Curent Year)
                    </h3>
                  </div>
                  <div className="indiv-box indiv-disc">
                    <div className="border-box disc-border">
                      <h2>{parseInt(totalPaid).toFixed(2)}</h2>
                    </div>
                    <h3>
                      total <br /> (From Beginning)
                    </h3>
                  </div>
                </div>

                <div className="disc-left">
                  <img src={ColorVerticalDots} alt="" />
                </div>
                <div className="disc-right">
                  <img src={ColorVerticalDots} alt="" />
                </div>
              </div>
              <div className="transaction-section">
      <div className="inner-transaction">
        <h1 className="section-heading">Notifications</h1>
        
          {notifications.length > 0 ?(
            
            notifications.map(item => {
              return(
                <div className="transaction-script" >
            <p style={{fontWeight:"bolder", fontSize:"0.75rem"}}>
            {item.message} {item.amount} <a target="_blank" style={{color: "#41BCD8"}} href={`https://goerli.etherscan.io/tx/${item.hash}`}>{item.hash}</a>
            </p>
            <div onClick={() => {deleteNotification(item.id)}} style={{position: "relative", padding: "0px", justifyContent: "center", marginBottom: "5%", width: "10%"}}>
            <img className="bell" src={bell} alt="" />
            <img className="redBell" src={redBell} />
            </div>
            
            
          </div>
          
              )
  
            })
            

            
          ):(
            <p style={{display: "flex", textAlign: "center", margin: "auto", fontSize: "1.5rem", color: "#fff", width: "90%", alignItems: "center", justifyContent: "center", fontWeight: "bolder" }}>
            You have no notifications.
          </p>
          
          )}
        
        
      </div>
    </div>
              <div className="active-section">
      <div className="bottom-left">
        <img src={LeftPolly} alt="" />
      </div>
      <div className="bottom-right">
        <img src={RightPolly} alt="" />
      </div>
      <h1 className="section-heading">Active Membership</h1>
      <div className="active-row">
        <div className="membership-input">
          <div className="mem-head">Intern</div>
          <div className="mem-blocks">
            <div className="indiv-main">
              <div className="indiv-input">{interns[0]}</div>
              <span className="keyword">M</span>
            </div>
            <div className="indiv-main">
              <div className="indiv-input">{interns[1]}</div>
              <span className="keyword">Q</span>
            </div>
            <div className="indiv-main">
              <div className="indiv-input">{interns[2]}</div>
              <span className="keyword">S</span>
            </div>
          </div>
        </div>
        <div className="membership-input">
          <div className="mem-head">Associate</div>
          <div className="mem-blocks">
            <div className="indiv-main">
              <div className="indiv-input">{associates[0]}</div>
              <span className="keyword">M</span>
            </div>
            <div className="indiv-main">
              <div className="indiv-input">{associates[1]}</div>
              <span className="keyword">Q</span>
            </div>
            <div className="indiv-main">
              <div className="indiv-input">{associates[2]}</div>
              <span className="keyword">S</span>
            </div>
          </div>
        </div>
        <div className="membership-input">
          <div className="mem-head">Executive</div>
          <div className="mem-blocks">
            <div className="indiv-main">
              <div className="indiv-input">{executives[0]}</div>
              <span className="keyword">M</span>
            </div>
            <div className="indiv-main">
              <div className="indiv-input">{executives[1]}</div>
              <span className="keyword">Q</span>
            </div>
            <div className="indiv-main">
              <div className="indiv-input">{executives[2]}</div>
              <span className="keyword">S</span>
            </div>
          </div>
        </div>
        <div className="membership-input top-mar">
          <div className="mem-head">President</div>
          <div className="mem-blocks">
            <div className="indiv-main">
              <div className="indiv-input">{presidents[0]}</div>
              <span className="keyword">M</span>
            </div>
            <div className="indiv-main">
              <div className="indiv-input">{presidents[1]}</div>
              <span className="keyword">Q</span>
            </div>
            <div className="indiv-main">
              <div className="indiv-input">{presidents[2]}</div>
              <span className="keyword">S</span>
            </div>
          </div>
        </div>
        <div className="membership-input top-mar">
          <div className="mem-head">Chairman</div>
          <div className="mem-blocks">
            <div className="indiv-main">
              <div className="indiv-input">{chairman[0]}</div>
              <span className="keyword">M</span>
            </div>
            <div className="indiv-main">
              <div className="indiv-input">{chairman[1]}</div>
              <span className="keyword">Q</span>
            </div>
            <div className="indiv-main">
              <div className="indiv-input">{chairman[2]}</div>
              <span className="keyword">S</span>
            </div>
          </div>
        </div>
        <div className="membership-input top-mar">
          <div className="mem-head">Corporate</div>
          <div className="mem-blocks">
            <div className="indiv-main">
              <div className="indiv-input">{corporate[0]}</div>
              <span className="keyword">M</span>
            </div>
            <div className="indiv-main">
              <div className="indiv-input">{corporate[1]}</div>
              <span className="keyword">Q</span>
            </div>
            <div className="indiv-main">
              <div className="indiv-input">{corporate[2]}</div>
              <span className="keyword">S</span>
            </div>
          </div>
        </div>
      </div>
    </div>
            </>
          ) : (
            <p style={{ textAlign: "center", padding: "200px", fontSize: "2rem" }}>You do not have permission to access this page.</p>
          )}
        </>
      ) : (
        <div style={{ display: "flex", padding: "200px", alignItems: "center", margin: "auto", left: "50%", justifyContent: "center" }}>
          <button style={{ textAlign: "center", alignItems: "center", margin: "auto", left: "50%", justifyContent: "center", fontSize: "2rem", height: "100px" }} className="connect-btn" onClick={connectWallet}>Connect Wallet</button>
        </div>
      )}


      <Footer />
    </div>
  );
};

export default AdminDashboard;
