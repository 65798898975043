import React, { useEffect, useState } from "react";
import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import Top from "../components/Gotop/top";
import Header from "../components/Memberdashboard/Header/header";
import Transactionhistory from "../components/Memberdashboard/TransactionHistory/transactionhistory";
import Footer from "../layout/Footer/footer";
import Web3 from 'web3';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import { Link } from "react-router-dom";
import { Close, Logo, Menu } from "../utils/allImgs";
import "./navbar.css";
import {
  ColorHorizonatlDots,
  ColorVerticalDots,
  CopyIcon,
  LeftReward,
  RightReward,
} from "../utils/allImgs";
import { bell, redBell } from "../utils/allImgs";
import { WhitecopyIcon } from "../utils/allImgs";
import "./redeempoints.css";
import "./rewardspoint.css";
import { LeftPolly, RightPolly } from "../utils/allImgs";
import { Network, Alchemy } from "alchemy-sdk";

// Setup: npm install alchemy-sdk
// Setup: npm install alchemy-sdk
// Github: https://github.com/alchemyplatform/alchemy-sdk-js

const settings = {
  apiKey: "EI8zrRsRs4HkigqcF04vBysFSm5kD_yC", // Replace with your Alchemy API Key.
  network: Network.ETH_GOERLI // Replace with your network.
};
const alchemy = new Alchemy(settings);




const web3 = createAlchemyWeb3("https://eth-mainnet.g.alchemy.com/v2/EI8zrRsRs4HkigqcF04vBysFSm5kD_yC");


const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      rpc: "https://eth-mainnet.g.alchemy.com/v2/EI8zrRsRs4HkigqcF04vBysFSm5kD_yC" // required
    }
  },
  coinbasewallet: {
    package: CoinbaseWalletSDK, // Required
    options: {
      appName: "JayTradz", // Required
      rpc: "https://eth-mainnet.g.alchemy.com/v2/EI8zrRsRs4HkigqcF04vBysFSm5kD_yC", // Optional if `infuraId` is provided; otherwise it's required

      darkMode: true // Optional. Use dark theme, defaults to false
    }
  }

};

const contractABI = require("../utils/contract-abi.json");
const usdtABI = require("../utils/usdt-abi.json");
const stakeABI = require("../utils/stake-abi.json");
const contractAddress = "0x65d780A86568BC340D04D3eA6D92ED5c3478FD37";//  0x324Ca102D35E323eB60157e520561d57785c39C8
const usdtAddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7";//  0x6BF7c471a8811d7cEE56C703E86809fB3A7b23AF
const stakeAddress = "0x8056Ab33327Bdd73b2720eC2a9Dbe8EAa4D6C254";
const NFT_IPFS_KEY = "QmYADUg4bJm7NLd7VwMAqcYuTp2tiT7B4nnfgsiknD4mNZ/"



const stakeContract = new web3.eth.Contract(
  stakeABI,
  stakeAddress
);


  



const MemberDashboard = () => {

  const [provider, setProvider] = useState();
  const [walletAddress, setAddress] = useState("");
  const [sideactive, setSideactive] = useState(false);
  const [class1, setClass1] = useState(true);
  const [class2, setClass2] = useState(false);
  const [class3, setClass3] = useState(false);
  const [class4, setClass4] = useState(false);
  const [class5, setClass5] = useState(false);
  const [class6, setClass6] = useState(false);
  const [class7, setClass7] = useState(false);
  const [monthlyCount, setMCount] = useState(1);
  const [quarterlyCount, setQCount] = useState(1);
  const [semiCount, setSemiCount] = useState(1);
  const [canStake, setCanStake] = useState(false);
  const [id, setId] = useState(1);
  const [listIds, setListIds] = useState([]);
  const [dash, setDashArray] = useState([]);
  const [claimPeriod, setClaimPeriodActive] = useState(false);
  const [claimTime, setClaimTime] = useState(0);
  const [total, setTotal] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [copyMessage, setCopyMessage] = useState("");
  const [gradBonus, setGradBonus] = useState(false);



  async function connectWallet() {

    try {

      let web3Modal = new Web3Modal({
        network: 'mainnet', // optional
        theme: 'dark',
        cacheProvider: false,

        providerOptions,

      });
      const web3ModalInstance = await web3Modal.connect();
      const provider = new Web3(web3ModalInstance);
      if (web3ModalInstance) {
        setProvider(provider);
        const accounts = await provider.eth.getAccounts();
        const address = accounts[0];
        setAddress(address);
        getNfts(address);
        getTimeLeft();
        isClaimPeriod();
        getNotifications(address);
        console.log(address);
      }

    } catch (error) {
      console.error(error)
    }
  }

  const toggleBar = () => {
    setSideactive(true);
  };
  const closeBar = () => {
    setSideactive(false);
  };
  if (sideactive) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }
  let active1 = () => {
    setClass1(true);
    setClass2(false);
    setClass3(false);
    setClass4(false);
    setClass7(false);
    setClass5(false);
    setSideactive(false);
    setClass6(false);
  };
  let active2 = () => {
    setClass1(false);
    setClass2(true);
    setClass3(false);
    setClass4(false);
    setClass5(false);
    setClass7(false);
    setSideactive(false);
    setClass6(false);
  };
  let active3 = () => {
    setClass1(false);
    setClass2(false);
    setClass3(true);
    setClass4(false);
    setClass5(false);
    setClass7(false);
    setSideactive(false);
    setClass6(false);
  };
  let active4 = () => {
    setClass1(false);
    setClass2(false);
    setClass3(false);
    setClass4(true);
    setClass5(false);
    setSideactive(false);
    setClass7(false);
    setClass6(false);
  };
  let active5 = () => {
    setClass1(false);
    setClass2(false);
    setClass3(false);
    setClass4(false);
    setClass5(true);
    setSideactive(false);
    setClass7(false);
    setClass6(false);
  };
  let active6 = () => {
    setClass1(false);
    setClass2(false);
    setClass3(false);
    setClass4(false);
    setSideactive(false);
    setClass5(false);
    setClass6(true);
    setClass7(false);
  };
  let active7 = () => {
    setSideactive(false);
    setClass1(false);
    setClass2(false);
    setClass3(false);
    setClass4(false);
    setClass5(false);
    setClass6(false);
    setClass7(true);
  };

  const isClaimPeriod = async () => {
    let claimPeriodActive = await stakeContract.methods.claimPeriodActive().call();
    setClaimPeriodActive(claimPeriodActive);
  }

  const getTimeLeft = async () => {
    let timeLeft = await stakeContract.methods.getTimeLeft().call();
    setClaimTime(timeLeft);
    console.log(timeLeft);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setClaimTime(claimTime => claimTime - 1);
    }, 1000);
    return () => clearInterval(interval);
    
  }, [])


  const getNfts = async (address) => {
    let ids = [];
    const allNfts = await stakeContract.methods.getMemberships(address).call();
    for (let i = 0; i < allNfts.length; i++) {
      
      let listId = allNfts[i];

      try {
      let tokenId = await stakeContract.methods.getTrueTokenID(listId).call();

      



      ids.push(<img
        key={i}
        src={`https://jaytradz.mypinata.cloud/ipfs/` + `${NFT_IPFS_KEY}` + `${tokenId}` + `.gif`}
        alt={listId}

      />

      )
      } catch(error) {
        console.log(error)
      }

    }

    setListIds(ids);
    console.log(ids);


  }


  const viewDashboard = async (i) => {

    let dashArray = [];


    let listId = i;

    let tokenId = await stakeContract.methods.getTrueTokenID(listId).call();
    let allPoints = await stakeContract.methods.getPoints(listId).call();
    console.log(allPoints);
    let [rewardPoints, referralPoints, royaltyPoints] = [allPoints[0], allPoints[1], allPoints[2]];
    let rewardPointsDec = rewardPoints / 10 ** 6;
    let referralPointsDec = referralPoints / 10 ** 6;
    let royaltyPointsDec = royaltyPoints / 10 ** 6;
    let adminPoints = await stakeContract.methods.getAdminPoints(listId).call();
    let adminPointsDec = adminPoints / 10 ** 6;
    let totalPointsDec = rewardPointsDec + referralPointsDec + royaltyPointsDec + adminPointsDec;
    let previousReward = await stakeContract.methods.getPreviousRewardPoints(listId).call();
    let previousRewardDec = previousReward[0] / 10 ** 6;
    let previousReferral = await stakeContract.methods.getPreviousReferralPoints(listId).call();
    let previousReferralDec = previousReferral[0] / 10 ** 6;
    let previousRoyalty = await stakeContract.methods.getPreviousRoyaltyPoints(listId).call();
    let previousRoyaltyDec = previousRoyalty[0] / 10 ** 6;

    let earned = previousReferralDec + previousRewardDec + previousRoyaltyDec + rewardPointsDec + referralPointsDec + royaltyPointsDec + adminPointsDec;

    let amountPaid = await stakeContract.methods.getAmountPaid(listId).call();
    let amountPaidDec = amountPaid / 10 ** 6;

    let withdrawn = earned - totalPointsDec;

    if(withdrawn < 0){
      withdrawn = 0
    }
    
    

    let refCode = await stakeContract.methods.getRefByID(listId).call();
    checkGradBonus(listId);





    dashArray.push(<img
      key={i}
      src={`https://jaytradz.mypinata.cloud/ipfs/` + `${NFT_IPFS_KEY}` + `${tokenId}` + `.gif`}
      alt={listId}
      trueID={tokenId}
      cReward={rewardPointsDec}
      cReferral={referralPointsDec}
      cRoyalty={royaltyPointsDec}
      pReward={previousRewardDec}
      pReferral={previousReferralDec}
      pRoyalty={previousRoyaltyDec}
      adminPoints={adminPointsDec}
      totalPoints={totalPointsDec}
      amountPaid={amountPaidDec}
      earned={earned}
      withdrawn={withdrawn}
      refCode={refCode}


    />

    )



    setDashArray(dashArray);
    console.log(dashArray);


  }
  const deleteDash = async () => {
    setDashArray([]);
  }

  const checkGradBonus = async (id) => {
    let checked = await stakeContract.methods.calculateGradBonus(id).call();
    setGradBonus(checked)
  }

  const claimGradBonus = async (id) => {
    await stakeContract.methods.claimGradBonus(id).send({from: walletAddress});
  }



  const payout = async (id, amount, cash, prize) => {
    await stakeContract.methods.payout(id, amount, cash, prize).send({ from: walletAddress, gas: 300000 });
  }

  const copyText = (id, refCode) => {
    
    if (id > 0 && id < 4) {
      navigator.clipboard.writeText
        (`https://jay-tradz-goerli.vercel.app?ref=${refCode}`);
    } else if (id >= 4 && id < 7) {
      navigator.clipboard.writeText
        (`https://jay-tradz-goerli.vercel.app?ref=${refCode}`);
    } else if (id >= 7 && id < 10) {
      navigator.clipboard.writeText
        (`https://jay-tradz-goerli.vercel.app?ref=${refCode}`);
    } else if (id >= 10 && id < 13) {
      navigator.clipboard.writeText
        (`https://jay-tradz-goerli.vercel.app?ref=${refCode}`);
    } else if (id >= 13 && id < 16) {
      navigator.clipboard.writeText
        (`https://jay-tradz-goerli.vercel.app?ref=${refCode}`);
    } else {
      navigator.clipboard.writeText
        (`https://jay-tradz-goerli.vercel.app?ref=${refCode}`);
    }

    setCopyMessage('Copied!');
    setTimeout(() => setCopyMessage(""), 1500);
    
    
    

  }
  const writeRef = async (tokenId) => {
    let id = await stakeContract.methods.getTrueTokenID(tokenId).call();
    let refCode = await stakeContract.methods.getRefByID(tokenId).call();
    if (id > 0 && id < 4) {
      navigator.clipboard.writeText
        (`${refCode}`);
    } else if (id >= 4 && id < 7) {
      navigator.clipboard.writeText
        (`${refCode}`);
    } else if (id >= 7 && id < 10) {
      navigator.clipboard.writeText
        (`${refCode}`);
    } else if (id >= 10 && id < 13) {
      navigator.clipboard.writeText
        (`${refCode}`);
    } else if (id >= 13 && id < 16) {
      navigator.clipboard.writeText
        (`${refCode}`);
    } else {
      navigator.clipboard.writeText
        (`${refCode}`);
    }

  }

  function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d +":" : "";
    var hDisplay = h > 0 ? (h < 10 ? "0"+h+":" : h+":") : "";
    var mDisplay = m > 0 ? (m < 10 ? "0"+m+":" : m+":") : "";
    var sDisplay = s > 0 ? (s < 10 ? "0"+s : s) : "";

    return (`Claim Period ends in ` + dDisplay + hDisplay + mDisplay + sDisplay);
  }


  function paddedToChecksumAddress(address) {
    if (address.slice(0, 2) === '0x') address = address.slice(2)
    while (address.slice(0, 2) === '00') address = address.slice(2)
    return web3.utils.toChecksumAddress('0x' + address)
  }

  const getNotifications = (address)=> {

    let userAddress = web3.utils.toChecksumAddress(address);
    console.log("The useAddress is: " + userAddress);

    

    alchemy.core
  .getLogs({
    address: '0x8056Ab33327Bdd73b2720eC2a9Dbe8EAa4D6C254',
    fromBlock: "earliest",
    toBlock: "latest",

  })
  .then((d) => {
    console.log(d);
   
    
    let messages = [];
    for(let i=d.length - 1; i > 0; i--) {

      if(d[i].topics.length > 1){

        if(paddedToChecksumAddress(d[i].topics[1]) == userAddress) {
      
      
        if(d[i].topics[0] == "0x58f286dbee15c187831d708a2a191759f2c64adc4bf0c21743402d027aa6d59b") {
          messages.push({id: i, message: "New Claim Period Open: ", hash: d[i].transactionHash});
  
          console.log("New Claim Period Open: " + d[i].transactionHash);
          
          
          
        } else if(d[i].topics[0] == "0xdc33abe64775189f2cda4edbfdb6c468d3ec923c885d95e4c0ca195a1bed7480"){
          messages.push({id: i, message: "Monthly Member Start: ", hash: d[i].transactionHash});
  
          console.log("Monthly Start: " + d[i].transactionHash);
          
        } else if(d[i].topics[0] == "0x27de4c19834e738cce1ee273df3e97bfb0fe7522f63c7fde2095e8c31b398dfd"){
          messages.push({id: i, message: "Quarterly Member Start: ", hash: d[i].transactionHash})
  
        } else if(d[i].topics[0] == "0x20da64b993a0e1ca1d080aa39914014d228525c23aab0ffbd721e05febe3931e"){
          messages.push({id: i, message: "SemiAnnual Member Start: ", hash: d[i].transactionHash})
  
        } else if(d[i].topics[0] == "0x698e6ae5d786c1b7ebbb3f65f5c53b321f0c2d2cc5de830ede8183fac06ec425"){
          messages.push({id: i, message: "Cash Payout ", hash: d[i].transactionHash, amount: String(`for $${(parseInt(web3.utils.toBN(d[i].data))/10**6)}:`)})
  
        } else if(d[i].topics[0] == "0x76a233ff16b2d0854c2063c3080d8e04a464382644f3ca7a65f409d381bcc8bc"){
          messages.push({id: i, message: "Flight Redeemed ", hash: d[i].transactionHash, amount: String(`for $${(parseInt(web3.utils.toBN(d[i].data))/10**6)}:`)})
        } else if(d[i].topics[0] == "0x2ea75d9e084137c84746e39d5496f87901be64f0215ec155bacc7f2c95c7faeb"){
          messages.push({id: i, message: "Hotel Redeemed ", hash: d[i].transactionHash, amount: String(`for $${(parseInt(web3.utils.toBN(d[i].data))/10**6)}:`)})
        } else if(d[i].topics[0] == "0x0fd6457e5f4f5b61d0a5dea18109c6a230ed93f49be5b48fdcb126a74647f2ff"){
  
          messages.push({id: i, message: "Concert Redeemed ", hash: d[i].transactionHash, amount: String(`for $${(parseInt(web3.utils.toBN(d[i].data))/10**6)}:`)})
        } else if(d[i].topics[0] == "0x6f9d986610e0a010f79e7bcc657d5939a4a8dbe039b5628a34e8bf8799ef43c1") {
  
          messages.push({id: i, message: "Swag Redeemed ", hash: d[i].transactionHash, amount: String(`for $${(parseInt(web3.utils.toBN(d[i].data))/10**6)}:`)})
        } else if(d[i].topics[0] == "0x473cbab0c154f1666a9caeff31cee09408525cedf7b184d6955bce0ac8dcec73"){
  
          messages.push({id: i, message: "Admin Points Awarded ", hash: d[i].transactionHash, amount: String(`for $${(parseInt(web3.utils.toBN(d[i].data))/10**6)}:`)})
        } else if(d[i].topics[0] == "0x27785514f6a2819dfa873afc0787b1b0633a2df4163b3a00cb2ffa6753d87634"){
          messages.push({id: i, message: "Claim Period Closed: ", hash: d[i].transactionHash});
        } else if(d[i].topics[0] == "0xbb28353e4598c3b9199101a66e0989549b659a59a54d2c27fbb183f1932c8e6d"){
          
          messages.push({id: i, message: "Refund ", hash: d[i].transactionHash, amount: String(`for $${(parseInt(web3.utils.toBN(d[i].data))/10**6)}:`)})
        } else if(d[i].topics[0] == "0xeeea19ad4eef4e8a1a16a94975c7c498aa1ae7910642deb5c0dee6889614050a"){

          messages.push({id: i, message: "Referral Earned ", hash: d[i].transactionHash});
        }
        
        
      }
    }
  }
  
      setNotifications([...notifications, ...messages]);
      
    
   
    
  })


  }
  

  const deleteNotification = (id) => {
    setNotifications((current) => 
    current.filter(notifications => notifications.id != id))
  }



  return (
    <div>

      <Top />

      <div className="navbar-section">
        <div className="navbar-main">
          <div className="left-bar">
            <Link to="/">
              <img src={Logo} alt="" />
            </Link>
          </div>
          <div
            onClick={closeBar}
            className={sideactive ? " overlay " : "hide-it"}
          ></div>
          <div className="right-bar" id="navMain">
            <ul className={sideactive ? "modalup" : "hide-it"}>
              <li className="mobile-close">
                <img onClick={closeBar} src={Close} alt="" />
              </li>
              <li>
                <a href="/" onClick={active1} className={class1 ? "active" : ""}>
                  Home
                  <span className="underline"></span>
                </a>
              </li>
              <li>
                <a
                  className={class2 ? "active" : ""}
                  href="/#program"
                  onClick={active2}
                >
                  Our Program
                  <span className="underline"></span>
                </a>
              </li>
              <li>
                <a
                  className={class3 ? "active" : ""}
                  href="/#mission"
                  onClick={active3}
                >
                  Mission
                  <span className="underline"></span>
                </a>
              </li>
              <li>
                <a
                  className={class4 ? "active" : ""}
                  href="/#membership"
                  onClick={active4}
                >
                  Memberships
                  <span className="underline"></span>
                </a>
              </li>
              <li>
                <a
                  className={class5 ? "active" : ""}
                  href="/#rewards"
                  onClick={active5}
                >
                  Rewards
                  <span className="underline"></span>
                </a>
              </li>
              <li>
                <a
                  className={class6 ? "active" : ""}
                  href="/#benefits"
                  onClick={active6}
                >
                  Benefits
                  <span className="underline"></span>
                </a>
              </li>
              <li>
                <a
                  className={class7 ? "active" : ""}
                  href="/#faq"
                  onClick={active7}
                >
                  FAQ
                  <span className="underline"></span>
                </a>
              </li>
              <li>
                <a
                  href="./JayTradz-Brochure.pdf"
                  onClick={closeBar}
                >
                  Brochure
                  <span className="underline"></span>
                </a>
              </li>

              <li>
                {walletAddress.length > 0 ? (
                  <button className='connect-btn-pressed'>
                    {String(walletAddress).substring(0, 6)}
                    {"...."}
                    {String(walletAddress).substring(39)}
                  </button>
                ) : (
                  <button className="connect-btn" onClick={connectWallet}>Connect Wallet</button>
                )}




              </li>
            </ul>
            <div className="mobile-bar">
              <img onClick={toggleBar} src={Menu} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Header />


      {walletAddress.length > 0 ? (
        <>
          {listIds.length > 0 ? (
            <div>
             
              {dash.length > 0 ? (
                <>
                  <div className="rewards-section pt-section" id="rewards">
                    <button style={{ margin: "auto", display: "flex", borderRadius: "10px", fontWeight: "bolder", padding: "6px", justifyContent: "center" }} onClick={deleteDash}>GO BACK</button>
                    <br></br>
                    {claimPeriod &&(
                      <p style={{display: "flex", margin: "auto", justifyContent: "center", textAlign: "center", fontWeight: "bolder", fontSize: "1.5rem"}}>{secondsToDhms(claimTime)}</p>
                    )}
                    
                    <br></br>
                    {gradBonus &&(
                      <>
                      <div style={{display: "block", margin: "auto", justifyContent: "center"}}>
                      <p style={{display: "flex", margin: "auto", justifyContent: "center", textAlign: "center", fontWeight: "bolder", fontSize: "1.5rem"}}>You Have a Graduation Bonus Available!🎉</p>
                      <button style={{marginTop: "10px", fontWeight: "bolder"}} onClick={()=> {claimGradBonus(dash[0].props.alt)}}className="ref-btn">Claim!</button>
                      </div>
                      <br></br>
                      </>

                    )}
                    <div className="left-reward">
                      <img src={LeftReward} alt="" />
                    </div>
                    <div className="right-reward">
                      <img src={RightReward} alt="" />
                    </div>
                    <div className="point-box">
                      <h2 className="point-number">{parseInt(dash[0].props.totalPoints).toFixed(0)}</h2>
                      <p>
                        REWARD POINTS EARNED <br /> THIS PERIOD
                      </p>
                    </div>
                    <h2 className="wallet-instruction">
                      For Memberships Connected to this Wallet
                    </h2>
                    <div className="wallet-address">
                      <p>{walletAddress}</p>
                    </div>
                    <div className="point-boxes">
                      <div className="indiv-box">
                        <div className="border-box">
                          <h2>{parseInt(dash[0].props.cReward).toFixed(0)}</h2>
                          <p>{parseInt(dash[0].props.pReward).toFixed(0)} / Max. {dash[0].props.amountPaid}</p>
                        </div>
                        <h3>MEMBERSHIP REWARD POINTS</h3>
                      </div>
                      <div className="indiv-box">
                        <div className="border-box">
                          <h2>{parseInt(dash[0].props.cReferral).toFixed(0)}</h2>
                          <p>{parseInt(dash[0].props.pReferral).toFixed(0)} / Max. {dash[0].props.amountPaid}</p>
                        </div>
                        <h3>REFERRAL REWARD POINTS</h3>
                      </div>
                      <div className="indiv-box">
                        <div className="border-box">
                          <h2>{parseInt(dash[0].props.cRoyalty).toFixed(0)}</h2>
                          <p>{parseInt(dash[0].props.pRoyalty).toFixed(0)} / Max. {dash[0].props.amountPaid}</p>
                        </div>
                        <h3>ROYALTY REFERRAL POINTS</h3>
                      </div>
                      <div className="indiv-box">
                        <div className="border-box">
                          <h2>{dash[0].props.adminPoints}</h2>
                          <p>{dash[0].props.adminPoints} / Max. No Cap</p>
                        </div>
                        <h3>ADMINISTRATIVE POINTS**</h3>
                      </div>
                    </div>
                    <div className="suggestion-line">
                      <p>
                        ** = Not subject to the Membership Reward Points Cap. (except
                        Anniversary Points) Administrative Credits; etc.
                      </p>
                    </div>
                    <button onClick={()=> {copyText(dash[0].props.trueID, dash[0].props.refCode)}} className="copyBtn">
                      <img src={CopyIcon} alt="" /> 
                      
                        Copy Your Referral Link
                     
                      
                    </button>
                    <div className="divider"></div>
                    <div className="background-box">
                      <div className="bx">
                        <div className="bx-bg">
                          <h2>{parseInt(dash[0].props.earned).toFixed(0)}</h2>
                        </div>
                        <h3>Total Points Earned</h3>
                      </div>
                      <div className="bx">
                        <div className="bx-bg">
                          <h2>{parseInt(dash[0].props.withdrawn).toFixed(0)}</h2>
                        </div>
                        <h3>Total Points Claimed</h3>
                      </div>
                    </div>
                    <div className="suggestion-line tp">
                      <p>Reward Points Earning/Disbursement History</p>
                    </div>
                    <div className="horizontal-dots">
                      <img src={ColorHorizonatlDots} alt="" />
                    </div>
                    <div className="vertical-dots">
                      <img src={ColorVerticalDots} alt="" />
                    </div>
                  </div>
                  <div className="redeempoints-section">
                    <div className="inner-redeempoint">
                      <h1 className="section-heading">REDEEM REWARD POINTS</h1>
                      <p className="section-detail">
                        All points may be exchanged for the reward of your choice at a rate of
                        1 point = $1USD. Third-party companies, such as travel agents, or
                        ticketing agents, shall provide some of the services in which points
                        are redeemed. Note: The selection of points in the form of
                        cryptocurrency shall be distributed to the wallet in which the
                        membership is located.
                      </p>
                      <div className="referral-main">
                        <div className="refrral-block">
                          <div className="refrral-heading">
                            <div className="left-heading">
                              <h3>
                                <span className="bold">FLIGHTS </span> : Domestic Flight to
                                Las Vegas
                              </h3>
                            </div>
                            <div className="right-heading">
                              <button onClick={() => { payout(dash[0].props.alt, 1000 * 10 ** 6, false, 1) }} className="price-btn gray">$1,000</button>
                              <button onClick={() => { payout(dash[0].props.alt, 2000 * 10 ** 6, false, 1) }} className="price-btn gray">$2,000</button>
                              <button onClick={() => { payout(dash[0].props.alt, 3000 * 10 ** 6, false, 1) }} className="price-btn gray">$3,000</button>
                              {/*<button className="price-btn gray redeem-btn">Redeem</button>*/}
                            </div>
                          </div>

                          <p className="instr">
                            Use your Rewards points to book a flight to Las Vegas. Select the
                            amount you want to use, and once you hit the redeem button, you
                            will receive a One-Time code that you can use with our agent to
                            purchase your flight(s). Please note that the amount you select
                            will be deducted from your total purchase with the agent. Any
                            amount unused shall be forfeited, and you are responsible for any
                            balance that goes over your redeemed amount.
                          </p>
                          <div className="card-btn">
                            <button onClick={()=> {writeRef(dash[0].props.alt)}} className="ref-btn">
                              Referral Code : {dash[0].props.refCode} <img src={WhitecopyIcon} alt="" />
                            </button>
                          </div>
                        </div>
                        <div className="refrral-block">
                          <div className="refrral-heading">
                            <div className="left-heading">
                              <h3>
                                <span className="bold">Hotel </span> : Hotel Stay in Las Vegas
                              </h3>
                            </div>
                            <div className="right-heading">
                              <button onClick={() => { payout(dash[0].props.alt, 1000 * 10 ** 6, false, 2) }} className="price-btn gray">$1,000</button>
                              <button onClick={() => { payout(dash[0].props.alt, 2000 * 10 ** 6, false, 2) }} className="price-btn gray">$2,000</button>
                              <button onClick={() => { payout(dash[0].props.alt, 3000 * 10 ** 6, false, 2) }} className="price-btn gray">$3,000</button>
                              {/*<button className="price-btn gray redeem-btn">Redeem</button>*/}
                            </div>
                          </div>

                          <p className="instr">
                            Use your Rewards points to book your hotel stay in Las Vegas.
                            Select the amount you want to use, and once you hit the redeem
                            button, you will receive a One-Time code that you can use with our
                            agent to book your hotel stay. Please note that the amount you
                            select will be deducted from your total purchase with the agent.
                            Any amount unused shall be forfeited, and you are responsible for
                            any balance that goes over your redeemed amount.
                          </p>
                          <div className="card-btn">
                            <button onClick={()=> {writeRef(dash[0].props.alt)}} className="ref-btn">
                              Referral Code : {dash[0].props.refCode} <img src={WhitecopyIcon} alt="" />
                            </button>
                          </div>
                        </div>
                        <div className="refrral-block">
                          <div className="refrral-heading">
                            <div className="left-heading">
                              <h3>
                                <span className="bold">CONCERTS/EVENTS TICKETS </span>
                              </h3>
                            </div>
                            <div className="right-heading">
                              <button onClick={() => { payout(dash[0].props.alt, 500 * 10 ** 6, false, 3) }} className="price-btn gray">$500</button>
                              <button onClick={() => { payout(dash[0].props.alt, 1000 * 10 ** 6, false, 3) }} className="price-btn gray">$1,000</button>
                              {/*<button className="price-btn gray redeem-btn">Redeem</button>*/}
                            </div>
                          </div>

                          <p className="instr">
                            Use your Rewards points to get tickets to the hottest concerts and
                            events in Las Vegas. Select the amount you want to use, and once
                            you hit the redeem button, you will receive a One-Time code that
                            you can use with our agent to purchase your concert/event
                            ticket(s). Please note that the amount you select will be deducted
                            from your total purchase with the agent. Any amount unused shall
                            be forfeited, and you are responsible for any balance that goes
                            over your redeemed amount.
                          </p>
                          <div className="card-btn">
                            <button onClick={()=> {writeRef(dash[0].props.alt)}} className="ref-btn">
                              Referral Code : {dash[0].props.refCode} <img src={WhitecopyIcon} alt="" />
                            </button>
                          </div>
                        </div>
                        <div className="refrral-block">
                          <div className="refrral-heading">
                            <div className="left-heading">
                              <h3>
                                <span className="bold">JayTradz "SWAG BAG" </span>
                              </h3>
                            </div>
                            <div className="right-heading">
                              <button onClick={() => { payout(dash[0].props.alt, 500 * 10 ** 6, false, 4) }} className="price-btn gray">$500</button>
                              {/*<button className="price-btn gray redeem-btn">Redeem</button>*/}
                            </div>
                          </div>

                          <p className="instr">
                            Get your JayTradz Swag Bag which includes hats, T-shirts, desktop
                            items, accessories, and more. Once you hit the redeem button, you
                            will receive a One-Time code that you can use with our agent to
                            get your stylish items delivered directly to you.
                          </p>
                          <div className="card-btn">
                            <button onClick={()=>{writeRef(dash[0].props.alt)}} className="ref-btn">
                              Referral Code : {dash[0].props.refCode} <img src={WhitecopyIcon} alt="" />
                            </button>
                          </div>
                        </div>
                        <div className="refrral-block">
                          <div className="refrral-heading">
                            <div className="left-heading">
                              <h3>
                                <span className="bold">CRYPTOCURRENCY </span> : ( USDT Amount)
                              </h3>
                            </div>
                            <div className="right-heading">
                              <input type="text " value={total} onChange={(e) => { setTotal(e.target.value) }} />
                              <button onClick={() => { setTotal(parseInt(dash[0].props.totalPoints).toFixed(2)) }} className="price-btn gray redeem-btn">Max</button>
                            </div>
                          </div>

                          <p className="instr">
                            Convert your Rewards points into cryptocurrency. Either enter the
                            amount that you wish to redeem as USDT, or select the MAX button;
                            confirm your wallet address; and then hit the withdrawal button.
                          </p>
                          <div className="card-btn">
                            <button className="ref-btn">
                              {walletAddress}
                            </button>
                          </div>
                          <div className="confirm-msg">
                            <h3>CONFIRM USDT (ERC-20) WALLET PAYOUT ADDRESS</h3>
                          </div>
                          <form onSubmit={(e)=> {e.preventDefault(); payout(dash[0].props.alt, total * 10 ** 6, true, 0)}} >
                          <div className="check-mark">
                            <input type="checkbox" name="" id="" required />
                            <p>
                              By checking this box, I acknowledge that i have read and
                              understand the TERMS OF SERVICE and our PRIVACY POLICY, and the
                              you full agree to the terms and to abid by the rules and
                              regulations.
                            </p>
                          </div>
                          <button type="submit" className="copyBtn dc">Withdraw</button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  <br></br>
                  <p style={{display: "flex", margin: "auto", justifyContent: "center", textAlign: "center", fontWeight: "bolder", fontSize: "1.5rem"}}>YOUR MEMBERSHIPS</p>
                  <br></br>
                  {claimPeriod &&(
                    <p style={{display: "flex", margin: "auto", justifyContent: "center", textAlign: "center", fontWeight: "bolder", fontSize: "1.5rem"}}>{secondsToDhms(claimTime)}</p>

                  )}
                  
                  {listIds.map(nft => {
                    
                    return (
                      <div style={{ margin: "auto", display: "inline-flex", padding: "20px", width: "300px", justifyContent: "center" }}>
                        <img
                          key={nft.props.alt}
                          src={nft.props.src}
                          style={{ display: "flex", margin: "auto", padding:"20px", cursor: "pointer", maxWidth: "250px", alignItems: "center", justifyContent: "center" }}
                          onClick={() => { viewDashboard(nft.props.alt) }} />
                      </div>
                    )
                   

                  })}
                  
                </div>
              )}

            </div>
          ) : (
            <p style={{ textAlign: "center", padding: "200px", fontSize: "2rem" }}>You don't have any memberships.</p>
          )}

        </>
      ) : (
        <div style={{ display: "flex", padding: "200px", alignItems: "center", margin: "auto", left: "50%", justifyContent: "center" }}>
          <button style={{ textAlign: "center", alignItems: "center", margin: "auto", left: "50%", justifyContent: "center", fontSize: "2rem", height: "100px" }} className="connect-btn" onClick={connectWallet}>Connect Wallet</button>
        </div>
      )}


<div className="transaction-section">
      <div className="inner-transaction">
        <h1 className="section-heading">Notifications</h1>
        
          {notifications.length > 0 ?(
            
            notifications.map(item => {
              return(
                <div className="transaction-script" >
            <p style={{fontWeight:"bolder", fontSize:"0.75rem"}}>
            {item.message} {item.amount} <a target="_blank" style={{color: "#41BCD8"}} href={`https://goerli.etherscan.io/tx/${item.hash}`}>{item.hash}</a>
            </p>
            <div onClick={() => {deleteNotification(item.id)}} style={{position: "relative", padding: "0px", justifyContent: "center", marginBottom: "5%", width: "10%"}}>
            <img className="bell" src={bell} alt="" />
            <img className="redBell" src={redBell} />
            </div>
            
            
          </div>
          
              )
  
            })
            

            
          ):(
            <p style={{display: "flex", textAlign: "center", margin: "auto", fontSize: "1.5rem", color: "#fff", width: "90%", alignItems: "center", justifyContent: "center", fontWeight: "bolder" }}>
            You have no notifications.
          </p>
          
          )}
        
        
      </div>
    </div>
    {copyMessage && (
      <div id="popup1" class="overlay" style={{ justifyContent: "center", margin: "auto"}}>
      <div class="popup" style={{width: "10%", textAlign: "center", marginTop: "10%", justifyContent: "center"}}>
        
      
        
        <div class="content" style={{marginTop: "10px"}}>
          {copyMessage}
            </div>
         
          
        </div>
      </div>
   
    )}
      <Footer />
    </div >
  );
};

export default MemberDashboard;
