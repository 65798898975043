import "./assets/css/style.css";
import React, {useContext} from "react";
import Routerapp from "./config/Router";

const RefContext = React.createContext();

const queryString = window.location.search;
const parameters = new URLSearchParams(queryString);
const value = parameters.get('ref');

console.log(value);



function App() {
  return (
    <div className="App">
      
      <Routerapp value={value}/>
      
    </div>
  );
}

export default App;
